import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectBench from "./SelectBench";
import { Button, Modal } from "react-bootstrap";
import { useGetInternalDataQuery } from "../../../redux/slices/catApiSlice";
import Loader from "../Loader";

const Menus = ({ menuId, homeLink }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { languageType } = useSelector((state) => state.catSliceExternal);
  const { data: headerMenus, isLoading } = useGetInternalDataQuery(
    `get-menu/${menuId}/header`
  );

  // function to remove
  const newSlug = (name) => {
    return name.replaceAll(" ", "-").toLowerCase();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="nav-wraper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light custom-nav  w-100 wow fadeInUp">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  {/* Menus start */}
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <NavLink to={homeLink}>
                        {languageType === "hid" ? "होम" : "Home"}
                      </NavLink>
                    </li>
                    {headerMenus && headerMenus.data ? (
                      headerMenus.data &&
                      headerMenus.data.map((item, index) => (
                        <li
                          key={index}
                          className={
                            item.submenu
                              ? "nav-item menu-item-expanded"
                              : "nav-item"
                          }
                        >
                          <NavLink to={item.submenu ? "#" : newSlug(item.name)}>
                            {languageType === "hid" && item.name === "Act"
                              ? "अधिनियम"
                              : item.name}
                          </NavLink>
                          <ul className="sub-menu">
                            {item.submenu &&
                              item.submenu.map((subItem, index) => (
                                <li key={index}>
                                  <NavLink to={newSlug(subItem.name)}>
                                    {subItem.name}
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))
                    ) : (
                      <>No data found</>
                    )}
                    <ul />
                    {menuId && menuId === 1 ? (
                      <li className="nav-item">
                        <a
                          onClick={() => setIsOpenModal(true)}
                          type="button"
                          href="#"
                        >
                          Case Management System
                        </a>
                      </li>
                    ) : (
                      <li className="nav-item menu-item-expanded">
                        <NavLink to="#">Case Management System</NavLink>
                        <ul className="sub-menu">
                          <li>
                            <NavLink to="case-status">Case Status</NavLink>
                          </li>
                          <li>
                            <NavLink to="cause-list">Cause List</NavLink>
                          </li>
                          <li>
                            <NavLink to="daily-order">Daily Order</NavLink>
                          </li>
                          <li>
                            <NavLink to="oral-and-final-order">
                              Oral and Final Order
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="case-proceeding-report">
                              Case Proceeding Report
                            </NavLink>
                          </li> */}
                        </ul>
                      </li>
                    )}
                    {menuId && menuId === 1 ? (
                      <li className="nav-item menu-item-expanded">
                        <NavLink to={`#`}>Other Links</NavLink>
                        <ul className="sub-menu">
                          <li>
                            <NavLink to="vacancies">Vacancies</NavLink>
                          </li>
                          <li>
                            <NavLink to="calendar">Calendar</NavLink>
                          </li>
                          <li>
                            <NavLink to="forms">Forms</NavLink>
                          </li>
                          <li>
                            <NavLink to="employee-corner">
                              Employee Corner
                            </NavLink>
                          </li>
                          {menuId && menuId === 20 && (
                            <li>
                              <a
                                href={`https://cis.cgat.gov.in/catlive/posh.php `}
                                target="_blank"
                                rel="noreferrer"
                              >
                                SHEBOX
                              </a>
                            </li>
                          )}
                          <li>
                            <NavLink to="tour-programme">
                              Tour Programme
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="notices/circulars">
                              Notices/Circulars
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item menu-item-expanded">
                        <NavLink to={`#`}>Other Links</NavLink>
                        <ul className="sub-menu">
                          <li>
                            <NavLink to={`${homeLink}vacancies`}>
                              Vacancies
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${homeLink}calendar`}>
                              Calendar
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${homeLink}forms`}>Forms</NavLink>
                          </li>
                          <li>
                            <NavLink to={`${homeLink}employee-corner`}>
                              Employee Corner
                            </NavLink>
                          </li>
                          {menuId && menuId === 20 && (
                            <li>
                              <a
                                href={`https://cis.cgat.gov.in/catlive/posh.php `}
                                target="_blank"
                                rel="noreferrer"
                              >
                                SHeBOX
                              </a>
                            </li>
                          )}
                          <li>
                            <NavLink to={`${homeLink}tour-programme`}>
                              Tour Programme
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={`${homeLink}notices/circulars`}>
                              Notices/Circulars
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                  <div class="eoffice">
                    <a href="https://eofficecadtr.cgat.gov.in/" target="_blank">
                      e-Office
                    </a>
                  </div>
                  <SelectBench />
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-select-bench"
        show={isOpenModal}
        onHide={() => setIsOpenModal(false)}
      >
        <Modal.Header>
          Message
          <Button
            type="button"
            className="close"
            onClick={() => setIsOpenModal(false)}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body>
          <h2>Please Select a Bench</h2>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Menus;

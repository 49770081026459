import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  fontSizeDecrease,
  fontSizeIncrease,
  fontSizeOriginal,
  setLanguageType,
} from "../../../redux/slice/sliceExternal/catSliceExternal";
import { loginUrl } from "../../../apis/apiUrl";

// Function to initialize Google Translate API
// function googleTranslateElementInit() {
//   new window.google.translate.TranslateElement(
//     { pageLanguage: " ", includedLanguages: "en,hi", autoDisplay: false },
//     "google_translate_element"
//   );
// }

const TopHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [togglelang, setToggle] = useState(false);
  const { fontSize } = useSelector((state) => state.catSliceExternal);

  // For Themes
  const blackTheme = () => {
    document.body.classList.add("black-theme");
  };

  const normalTheme = () => {
    document.body.classList.remove("black-theme");
  };

  //skip to main content
  const skipToMain = () => {
    window.scrollTo({
      top: 500,
      behavior: "smooth",
    });
  };

  // google translate
  useEffect(() => {
    var flags = document.getElementsByClassName("flag_link-hi");
    var flagsen = document.getElementsByClassName("flag_link-en");

    Array.prototype.forEach.call(flags, function (e) {
      e.addEventListener("click", function () {
        dispatch(setLanguageType("hid"));
        console.log("hindi clicked");
        var lang = e.getAttribute("data-lang");
        var languageSelect = document.querySelector("select.goog-te-combo");
        languageSelect.value = lang;
        languageSelect.dispatchEvent(new Event("change"));
      });
    });

    Array.prototype.forEach.call(flagsen, function (e) {
      e.addEventListener("click", function () {
        var lang = e.getAttribute("data-lang");
        var languageSelect = document.querySelector("select.goog-te-combo");
        languageSelect.value = lang;
        languageSelect.dispatchEvent(new Event("change"));
        dispatch(setLanguageType("eng"));
        console.log("english clicked");
      });
    });
  }, []);

  return (
    <div className="top-header">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 top-left-nav wow fadeInLeft">
            <ul>
              <li>
                <a href="#" className="hide">
                  <img src="images/india.png" alt="india" />
                </a>
              </li>
              <li className="gov-txt">
                <a
                  className="float-right"
                  target="_blank"
                  href="https://india.gov.in/hi/"
                  role="link"
                >
                  भारत सरकार{" "}
                </a>
                <a href="#">Government of India</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 top-right-nav wow fadeInLeft">
            <ul>
              <li>
                <Link className="hide" to="/" aria-label="Home">
                  <i className="fas fa-home"></i>
                </Link>
              </li>
              <li>
                <a
                  href="https://cis.cgat.gov.in/catlive/home.php"
                  target="_blank"
                >
                  Old Website Link
                </a>
              </li>
              <li className="border-right-0">
                <a
                  href={process.env.REACT_APP_MOBILE_APP_LINK}
                  target="_blank"
                  className="hide getapp"
                >
                  Get Mobile App
                </a>
              </li>
              <li>
                <a
                  href="#"
                  type="button"
                  className="hide skiptomain"
                  onClick={skipToMain}
                  aria-label="Skip to Main"
                >
                  Skip to Main Content
                </a>
              </li>
              <li>
                <Link
                  type="button"
                  className="hide"
                  to="screenreader"
                  aria-label="Screen Reader"
                >
                  Screen Reader Access
                </Link>
              </li>
              <li className="text-size">
                <a
                  href="#"
                  type="button"
                  className="pt-1"
                  onClick={() => {
                    if (fontSize < 33) {
                      dispatch(fontSizeIncrease());
                    }
                  }}
                  aria-label="Font Size Increase button"
                >
                  A+
                </a>
                <a
                  href="#"
                  type="button"
                  className="pt-1 "
                  onClick={() => dispatch(fontSizeOriginal())}
                  aria-label="Font Size Original button"
                >
                  A
                </a>
                <a
                  href="#"
                  type="button"
                  className="pt-1"
                  onClick={() => {
                    if (fontSize > 10) {
                      dispatch(fontSizeDecrease());
                    }
                  }}
                  aria-label="Font Size Decrease button"
                >
                  A -
                </a>
                <a
                  href="#"
                  type="button"
                  className="whitebg p-1"
                  onClick={normalTheme}
                  aria-label="Light theme mode"
                >
                  A
                </a>
                <a
                  href="#"
                  type="button"
                  className="blackbg p-1"
                  onClick={blackTheme}
                  aria-label="Dark theme mode"
                >
                  A
                </a>
              </li>
              <li>
                <a href={loginUrl} target="_blank" aria-label="Login">
                  <i className="fas fa-user-lock"></i>
                  &nbsp;&nbsp;Login
                </a>
              </li>
              <li className="lang" aria-hidden="true">
                <span
                  className="google-translate-wrap"
                  id="google_translate_element"
                  aria-hidden="true"
                ></span>
                {/* <Lang /> */}
                {/* <div className="flag">
                <span href="#" className="flag_link-en en" data-lang="en">
                  English
                </span>{" "}
                <br />
                <span href="#" className="flag_link-hi hi" data-lang="hi">
                  hindi
                </span>
              </div> */}
              </li>
              <li className="lang-custom">
                <a
                  onClick={() => setToggle(!togglelang)}
                  type="button"
                  href="#"
                >
                  Select&nbsp;Language&nbsp;
                  <i className="fas fa-caret-down"></i>
                </a>
                <div className={togglelang ? "flag flag-language" : "flag"}>
                  {/* {togglelang ? ( */}
                  <span
                    className="flag_link-en en"
                    onClick={() => setToggle(!togglelang)}
                    data-lang="en"
                  >
                    English
                  </span>
                  {/* ) : ( */}
                  <span
                    className="flag_link-hi hi"
                    onClick={() => setToggle(!togglelang)}
                    data-lang="hi"
                  >
                    हिंदी
                  </span>
                  {/* )} */}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
